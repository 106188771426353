<template lang="html" src="./pageServices.template.vue"></template>

<style lang="scss" src="./pageServices.scss"></style>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageServices.i18n');

export default {
  name: 'PageServices',
  components: {},
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loading: false,
      quickbooksTokenEdit: false,
      servicesStatus: {
        quickbooks: null,
        boxtal: null,
        stripe: null,
        mailjet: null,
      },
      headersTaxCodes: [],
      hideDisabled: true,
      taxCodes: [],
      token: null,
    };
  },
  computed: {
    computedTaxCodes() {
      return this.taxCodes.filter(t => {return t.Active || !this.hideDisabled;});
    },
  },
  created() {
    this.headersTaxCodes = [
      {
        text: this.$t('Name'),
        value: 'Name'
      },
      {
        text: this.$t('Description'),
        value: 'Description'
      },
      {
        text: 'Id',
        value: 'Id'
      },
      {
        text: this.$t('Active'),
        value: 'Active'
      }
    ];
    this.init();
  },
  methods: {
    init() {
      this.getServicesStatus();
      this.getTaxCodes();
    },
    getServicesStatus() {
      this.loading = true;
      this.token = '';
      this.servicesStatus = {
        quickbooks: null,
        boxtal: null,
        stripe: null,
        mailjet: null,
      };
      /**
       * Quickbooks
       */
      this.$apiInstance
        .quickbooksConnect()
        .then(
          () => {
            this.servicesStatus.quickbooks = 200;
          },
          (error) => {
            if (error.hasOwnProperty('status')) {
              this.servicesStatus.quickbooks =
                error.status !== 200 ? error.status : 500;
            }

            /**
             * ERROR GET QUOTE
             *
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.checkStatus();
          this.quickbooksTokenEdit = false;
        });
      /**
       * Boxtal
       */
      this.$apiInstance
        .shippingBoxtalGetCategories()
        .then(
          () => {
            this.servicesStatus.boxtal = 200;
          },
          (error) => {
            this.servicesStatus.boxtal = error.code;
            /**
             * ERROR GET QUOTE
             *
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.checkStatus();
        });
      /**
       * Stripe
       */
      this.$apiInstance
        .getStatusStripe()
        .then(
          () => {
            this.servicesStatus.stripe = 200;
          },
          (error) => {
            this.servicesStatus.stripe = error.code;
            /**
             * ERROR GET QUOTE
             *
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.checkStatus();
        });
      /**
       * Mailjet
       */
      this.$apiInstance
        .getStatusMailjet()
        .then(
          () => {
            this.servicesStatus.mailjet = 200;
          },
          (error) => {
            this.servicesStatus.mailjet = error.code;
            /**
             * ERROR GET QUOTE
             *
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.checkStatus();
        });
    },
    getTaxCodes() {
      this.taxCodes = [];
      this.$apiInstance.getQuickbooksTaxCodes()
        .then(data => {
          this.taxCodes = data;
        });
    },
    updateToken() {
      const modifyQuickbooksRefreshTokenBody = new this.$BcmModel.ModifyQuickbooksRefreshTokenBody();
      modifyQuickbooksRefreshTokenBody.refreshToken = this.token;
      this.$apiInstance
        .modifyQuickbooksRefreshToken(modifyQuickbooksRefreshTokenBody)
        .then(
          () => {},
          (error) => {
            /**
             * ERROR MODIFY BRAND
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.getServicesStatus();
        });
    },
    checkStatus() {
      if (
        this.servicesStatus.quickbooks &&
        this.servicesStatus.boxtal &&
        this.servicesStatus.mailjet &&
        this.servicesStatus.stripe
      ) {
        this.loading = false;
      }
    },
  },
};
</script>
